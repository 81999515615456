import * as Sentry from "@sentry/nextjs";
import { getSession } from "next-auth/react";

import { HandledGQLErrorCodes, SanitizedGQLError } from "@/types/errors";

interface GQLClient {
  headers: Headers;
  request: <T = any>(
    query: string,
    variables?: Record<string, any>,
    requestHeaders?: Record<string, string>
  ) => Promise<T>;
}

export const gqlClient: GQLClient = {
  headers: new Headers(),
  async request(query, variables, requestHeaders) {
    const res = await fetch(process.env.NEXT_PUBLIC_API_URL as string, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...Object.fromEntries(this.headers),
        ...requestHeaders,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    // Throw generic error if request failed or response is not JSON
    if (!res.ok || !res.headers.get("content-type")?.includes("application/json")) {
      throw new Error("An unexpected error occurred. Please try again.");
    }

    const { data, errors } = await res.json();

    if (errors) {
      // Create a sanitized error object for GQL errors
      const sanitizedError = new SanitizedGQLError(errors);

      /** Lazy session refresh */
      if (sanitizedError.code === HandledGQLErrorCodes.UNAUTHENTICATED) {
        // This will refresh the access token if it has expired
        const session = await getSession();

        // If there is a session and its still valid, retry the request with the new token
        if (session && !session.error) {
          this.headers.set("authorization", `Bearer ${session.accessToken}`);
          return this.request(query, variables, requestHeaders);
        }
      }

      // We don't really care about auth error, just unexpected ones
      if (sanitizedError.code === "INTERNAL_SERVER_ERROR") {
        Sentry.captureException(sanitizedError, {
          extra: { query, variables, ...sanitizedError.getDetails() },
        });
      }

      throw sanitizedError;
    }

    return data;
  },
};
